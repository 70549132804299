@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Patua+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body,
#__next {
  display: flex;
  width: 100%;
  position: relative;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 0;
  @apply min-h-screen text-black bg-secondary-white font-merriweather;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  @apply text-2xl sm:text-3xl font-primary font-bold leading-4xl;
}

h2 {
  @apply text-2xl font-primary font-bold leading-3xl;
}

h3 {
  @apply text-xl font-primary font-bold leading-2xl;
}

h4 {
  @apply text-lg font-primary font-bold leading-xl;
}

h5 {
  @apply text-base font-primary font-bold leading-lg;
}

h6 {
  @apply text-sm font-primary font-bold leading-sm;
}

p {
  @apply font-merriweather text-base leading-relaxed;

  &.body-lg {
    @apply text-base sm:text-lg leading-xl;
  }

  &.body-sm {
    @apply text-sm leading-base;
  }
}

.container {
  @apply max-w-7xl mx-auto px-4 w-full;
}

.container-sm {
  @apply max-w-2xl mx-auto;
}

.shadow {
  box-shadow: 4px 4px 35px rgba(216, 212, 193, 0.5);
}

.member-card {
  background: linear-gradient(
    98.87deg,
    #fff8d0 -16.91%,
    rgba(255, 255, 255, 0.2) 74.87%,
    rgba(255, 255, 255, 0) 99.39%
  );
  box-shadow: 19px 4px 35px rgba(216, 212, 193, 0.5);
}
